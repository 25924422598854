import { masterChefV3Addresses } from '@pancakeswap/farms'
import { DEPLOYER_ADDRESSES } from '@pancakeswap/v3-sdk'
import { V3_QUOTER_ADDRESSES } from '@pancakeswap/smart-router/evm'

export default {
  masterChef: {
    1: '0xDa4A7cB168362eE4A8e19129bc2283503196d1Bb',
    97: '0xB4A466911556e39210a6bB2FaECBB59E4eB7E43d',
    56: '0xb2B9da983DA1850EEeE093106038e36855401f66',
    651940: '0x74695767FAf9775d929ba5169f256C60b7ea08b4',
  },
  masterChefV3: masterChefV3Addresses,
  masterChefV1: {
    1: '0x4A712867409F569038C919C6e32550C8f3A73939',
    97: '0x1d32c2945C8FDCBc7156c553B7cEa4325a17f4f9',
    56: '0x85Ed6f37020919A7E1Cfa91349C4A076787738A7',
    651940: '0xD023D08B851eC3A7c4660846d1436beD6dE41EaC',
  },
  sousChef: {
    97: '0xd3af5fe61dbaf8f73149bfcfa9fb653ff096029a',
    56: '0x6ab8463a4185b80905e05a9ff80a2d6b714b9e95',
  },
  lotteryV2: {
    97: '0x5790c3534F30437641541a0FA04C992799602998',
    56: '0x5aF6D33DE2ccEC94efb1bDF8f92Bd58085432d2c',
  },
  multiCall: {
    1: '0xcA11bde05977b3631167028862bE2a173976CA11',
    4: '0xcA11bde05977b3631167028862bE2a173976CA11',
    5: '0xcA11bde05977b3631167028862bE2a173976CA11',
    56: '0xcA11bde05977b3631167028862bE2a173976CA11',
    97: '0xcA11bde05977b3631167028862bE2a173976CA11',
    280: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    324: '0xF9cda624FBC7e059355ce98a31693d299FACd963',
    651940: '0x12533aC78769ab8BcAA96D6084e02E6ab09930E6',
  },
  pancakeProfile: {
    1: '0x7F750b4fD9f336C044f8d31aF7489a28e16df705',
    56: '0xd7C0c61f30D421287ff382047532E1ab76894371',
    97: '0x4B683C7E13B6d5D7fd1FeA9530F451954c1A7c8A',
    651940: '0x8617E5D4D7D3CDf9F5C61970a99c9D831aAa1911',
  },
  pancakeBunnies: {
    1: '0xfe23E6cB28E14D68d4c3e5ee405AeeB917b00DB1',
    56: '0xbD52a7A11B84d35D549D752e760Aee4742078739',
    97: '0x60935F36e4631F73f0f407e68642144e07aC7f5E',
    651940: '0x7a7899B156a470e8dd3b6E4DA6b2eB1ee4CE755A',
  },
  // BunnyFactoryV3
  bunnyFactory: {
    1: '0x7c0d88bDb5128De5d6D6cF28038E47941E5BEaf6',
    56: '0x230d4ae4e28256875a80a9a0277354D9Cf41820C',
    97: '0x707CBF373175fdB601D34eeBF2Cf665d08f01148',
    651940: '0x141AA7393E62008D150Aa01A1761065f00900e94',
  },
  claimRefund: {
    56: '0xE7e53A7e9E3Cf6b840f167eF69519175c497e149',
    97: '0x',
  },
  pointCenterIfo: {
    1: '0xDCEAcb6411F16a041960c22DF916Ca70Aa319204',
    56: '0x5fEf5138F0Ac3Cf7Ce3374f0689Af1eA807d3b26',
    97: '0xd2Ac1B1728Bb1C11ae02AB6e75B76Ae41A2997e3',
    651940: '0xa281dC51c1Ef5D0367495134F8d837E843f13Ab9',
  },
  bunnySpecial: {
    56: '0xFee8A195570a18461146F401d6033f5ab3380849',
    97: '0x7b7b1583De1DeB32Ce6605F6deEbF24A0671c17C',
    651940: '0xF6CcEbd019f275c0Fb272E1f38E949E856A8C305',
  },
  tradingCompetitionEaster: {
    56: '0xd718baa0B1F4f70dcC8458154042120FFE0DEFFA',
    97: '0xC787F45B833721ED3aC46E99b703B3E1E01abb97',
    651940: '0xc7603900b684A705AA891CF8FCC6fa48528630DC',
  },
  tradingCompetitionFanToken: {
    56: '0xA8FECf847e28aa1Df39E995a45b7FCfb91b676d4',
    97: '0x',
  },
  tradingCompetitionMobox: {
    56: '0x1C5161CdB145dE35a8961F82b065fd1F75C3BaDf',
    97: '0x',
  },
  tradingCompetitionMoD: {
    56: '0xbDd9a61c67ee16c10f5E37b1D0c907a9EC959f33',
    97: '0x',
  },
  easterNft: {
    56: '0x23c41D28A239dDCAABd1bb1deF8d057189510066',
    97: '0x24ec6962dbe874F6B67B5C50857565667fA0854F',
    651940: '0x9DD3D84Ef94344A2cc72B1f028f8CC81B8e1A2cd',
  },
  // CakePool
  cakeVault: {
    1: '0x88300E1490585208Aa5238deDa1BF9cF7244Bb22',
    56: '0x4Fd06A69624FC5C4B077B8890DeBC9cf0d91F1A7',
    97: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
    651940: '0x27A6ed9dD4d12a9eAee4eD0E41D84dcCe4B7178D',
  },
  cakeFlexibleSideVault: {
    1: '0x0b11e66406237143Cb08B0310d4D900d953b0Db7',
    56: '0x6f876A7ce2e78A7D3030c04DfD3E84B06Ab7d006',
    97: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
    651940: '0xCfA94C3dbC63BE5863eCe2BAc614e7b4EE1AC41a',
  },
  predictionsBNB: {
    56: '0x18B2A687610328590Bc8F2e5fEdDe3b582A49cdA',
    97: '0x',
  },
  predictionsCAKE: {
    56: '0x0E3A8078EDD2021dadcdE733C6b4a86E51EE8f07',
    97: '0x',
  },
  chainlinkOracleBNB: {
    56: '0x0567F2323251f0Aab15c8dFb1967E4e8A7D42aeE',
    97: '0x',
  },
  chainlinkOracleCAKE: {
    56: '0xB6064eD41d4f67e353768aA239cA86f4F73665a1',
    97: '0x',
  },
  predictionsV1: {
    56: '0x516ffd7d1e0ca40b1879935b2de87cb20fc1124b',
    97: '0x',
  },
  bunnySpecialCakeVault: {
    56: '0x5B4a770Abe7Eafb2601CA4dF9d73EA99363E60a4',
    97: '0x',
  },
  bunnySpecialPrediction: {
    56: '0x342c99e9aC24157657095eC69CB04b73257e7A9C',
    97: '0x',
  },
  bunnySpecialLottery: {
    56: '0x24ED31d31C5868e5a96aA77fdcB890f3511fa0b2',
    97: '0x382cB497110F398F0f152cae82821476AE51c9cF',
  },
  bunnySpecialXmas: {
    56: '0x59EdDF3c21509dA3b0aCCd7c5ccc596d930f4783',
    97: '0x',
  },
  farmAuction: {
    56: '0xb92Ab7c1edcb273AbA24b0656cEb3681654805D2',
    97: '0x3F9602593b4f7C67ab045DB51BbDEa94E40fA9Fe',
  },
  nftMarket: {
    1: '0xbD154090fc0Ec47B024A8FfbDF8e28132CFe004E',
    56: '0x52c4a5E1E65969992c1AD692D6FA6A59B775F354',
    97: '0x7f9f37ddcaa33893f9beb3d8748c8d6bfbde6ab2',
    651940: '0xCE788e49B70Ec2fBb2DE4a4Ca7300AB46522f84B',
  },
  nftSale: {
    56: '0x8C706a34c94CCdE7b944101e60Db5D8CDdB1380D',
    97: '0xe486De509c5381cbdBF3e71F57D7F1f7570f5c46',
    651940: '0x8C706a34c94CCdE7b944101e60Db5D8CDdB1380D',
  },
  pancakeSquad: {
    56: '0x0a8901b0E25DEb55A87524f0cC164E9644020EBA',
    97: '0xfC0c3F11fDA72Cb9A56F28Ec8D44C0ae4B3ABF86',
    651940: '0xeEB9A607652F1Ba76Ab96842cE9dd810203FC5C2',
  },
  potteryDraw: {
    56: '0x01871991587d5671f3A2d4E2BcDC22F4E026396e',
    97: '0xDB9D365b50E62fce747A90515D2bd1254A16EbB9',
  },
  zap: {
    56: '0xD4c4a7C55c9f7B3c48bafb6E8643Ba79F42418dF',
    97: '0xD85835207054F25620109bdc745EC1D1f84F04e1',
  },
  stableSwapNativeHelper: {
    56: '0x52E5D1e24A4308ef1A221C949cb2F7cbbAFEE090',
    97: '0x6e4B1D7C65E86f1723720a5fE8993f0908108b64',
  },
  iCake: {
    1: '0x5D344AF105958c34fAF15bF28d3088c6e7b66967',
    56: '0x27A6ed9dD4d12a9eAee4eD0E41D84dcCe4B7178D',
    97: '0x',
    651940: '0xc675a516eea0605F66b11B8a72Cd2561f645C950',
  },
  // FarmBoosterV2
  bCakeFarmBooster: {
    1: '0x0AD0BeCE059Bc20C279749bc3A79d67Dc6e52999',
    56: '0x73EA3A23cf6FE5A2984C28183312e31C4A097b1a',
    97: '0x',
    651940: '0x8C7e40Ca830AF302699b175014337C54ea3e41E0',
  },
  // FarmBoosterProxyFactory
  bCakeFarmBoosterProxyFactory: {
    1: '0x3B13198c34609eb0cfCcDE0F4852c9707E4b47ee',
    56: '0x2824C682bB271B91781F4d6c68EE82e7aA26CBDc',
    97: '0x',
    651940: '0x0AD0BeCE059Bc20C279749bc3A79d67Dc6e52999',
  },
  // FarmBoosterV3
  bCakeFarmBoosterV3: {
    1: '0x8C7e40Ca830AF302699b175014337C54ea3e41E0',
    56: '0x8Fb6A76Fed322A054EA799c3293123A6eeCe0e53',
    97: '0x56666300A1E25624489b661f3C6c456c159a109a',
    651940: '0x77bF3407E861dAf66B1323D40f8144D591E30508',
  },
  nonBscVault: {
    1: '0x2e71B2688019ebdFDdE5A45e6921aaebb15b25fb',
    5: '0xE6c904424417D03451fADd6E3f5b6c26BcC43841',
  },
  crossFarmingSender: {
    1: '0x8EA90Ef07f37c77137453C7A1B72B7886d51eCFb',
    5: '0x327d26dE30f92600620A99043034e0A5FD9402C8',
  },
  crossFarmingReceiver: {
    56: '0x0726a8C8206b9eC0AfB788df5adb36a8AEDB13c2',
    97: '0xBab5d3B6bA24E185f216419f3ba07f03984bF983',
  },
  mmLinkedPool: {
    1: '0x9Ca2A439810524250E543BA8fB6E88578aF242BC',
    5: '0x7bb894Ca487568dD55054193c3238d7B1f46BB92',
    56: '0xfEACb05b373f1A08E68235bA7FC92636b92ced01',
  },
  tradingReward: {
    1: '0x',
    56: '0xa842a4AD40FEbbd034fbeF25C7a880464a90e695',
    97: '0x',
  },
  // NonfungiblePositionManager
  nftPositionManager: {
    1: '0x02F883b0A3038B5F83Fc0F17B0b93da9BEA21a15',
    5: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    56: '0x3E578da365DF6300459185D6a2AC48a6BE46255f',
    97: '0x427bF5b37357632377eCbEC9de3626C71A5396c1',
    280: '0xF84697CfE7c88F846e4ba5dAe14A6A8f67deF5c2',
    324: '0xa815e2eD7f7d5B0c49fda367F249232a1B9D2883',
    1101: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    1442: '0x1f489dd5B559E976AE74303F939Cfd0aF1b62C2B',
    42161: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    59140: '0xacFa791C833120c769Fd3066c940B7D30Cd8BC73',
    421613: '0xb10120961f7504766976A89E29802Fa00553da5b',
    651940: '0x02F883b0A3038B5F83Fc0F17B0b93da9BEA21a15',
  },
  v3PoolDeployer: DEPLOYER_ADDRESSES,
  v3Migrator: {
    1: '0xed0BBD313B7AB565F634d58b915a534d9e53b003',
    5: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    56: '0xfC0bAe146969835dDe101fF386f5E8d20A65D426',
    97: '0x46A15B0b27311cedF172AB29E4f4766fbE7F4364',
    280: '0x7627931617A60Fe58EDBf4881ac166E1eDe2379e',
    324: '0x556A72A7A3bB3bbd293D923e59b6B56898fB405D',
    1101: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    1442: '0x4A3902773F947ce028969db670E568fFC9524E8C',
    42161: '0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2',
    59140: '0x3652Fc6EDcbD76161b8554388867d3dAb65eCA93',
    421613: '0xCcf770BdBD8ccC57a7a7ABff53825fD895a06238',
    651940: '0xed0BBD313B7AB565F634d58b915a534d9e53b003',
  },
  quoter: V3_QUOTER_ADDRESSES,
  v3Airdrop: {
    1: '0x',
    56: '0xe934d2C5bE5db0295A4de3177762A9E8c74Ae4f4',
    97: '0x',
  },
  affiliateProgram: {
    1: '0x',
    56: '0x92C73D90F709DFf7e5E7307e8F2EE20e39396b12',
    97: '0x1B8a475B5E5De05fB3Ac2D9ec3C2809fBF24e51c',
  },
  tradingRewardTopTrades: {
    1: '0x',
    56: '0x41920b6A17CB73D1B60f4F41D82c35eD0a46fD71',
    97: '0x',
  },
  vCake: {
    1: '0x',
    56: '0xa3b8321173Cf3DdF37Ce3e7548203Fc25d86402F',
    97: '0x5DD37E97716A8b358BCbc731516F36FFff978454',
  },
  revenueSharingPool: {
    1: '0x',
    56: '0xCD5d1935e9bfa4905f9f007C97aB1f1763dC1607',
    97: '0xd2d1DD41700d9132d3286e0FcD8D6E1D8E5052F5',
  },
} as const satisfies Record<string, Record<number, `0x${string}`>>
