import {
  MenuItemsType,
  DropdownMenuItemType,
  SwapIcon,
  SwapFillIcon,
  EarnFillIcon,
  PancakeProtectorIcon,
  EarnIcon,
  TrophyIcon,
  TrophyFillIcon,
  NftIcon,
  NftFillIcon,
  MoreIcon,
  DropdownMenuItems,
  ChartIcon,
  BridgeIcon,
} from '@pancakeswap/uikit'
import { ContextApi } from '@pancakeswap/localization'
import { SUPPORTED_CHAIN_IDS as POOL_SUPPORTED_CHAINS } from '@pancakeswap/pools'
import { nftsBaseUrl } from 'views/Nft/market/constants'
import { getPerpetualUrl } from 'utils/getPerpetualUrl'
import { SUPPORT_FARMS, SUPPORT_ONLY_BSC, SUPPORT_ONLY_ALL, SUPPORT_NFT, SUPPORT_IFO } from 'config/constants/supportChains'
import { NewIconButton } from 'views/BuyCrypto/components/NewIcon'

export type ConfigMenuDropDownItemsType = DropdownMenuItems & { hideSubNav?: boolean }
export type ConfigMenuItemsType = Omit<MenuItemsType, 'items'> & { hideSubNav?: boolean; image?: string } & {
  items?: ConfigMenuDropDownItemsType[]
}

const addMenuItemSupported = (item, chainId) => {
  if (!chainId || !item.supportChainIds) {
    return item
  }
  if (item.supportChainIds?.includes(chainId)) {
    return item
  }
  return {
    ...item,
    disabled: true,
  }
}

const config: (
  t: ContextApi['t'],
  isDark: boolean,
  languageCode?: string,
  chainId?: number,
) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
  [
    {
      label: t('Trade'),
      icon: SwapIcon,
      fillIcon: SwapFillIcon,
      href: '/swap',
      showItemsOnMobile: false,
      items: [
        {
          label: t('Swap'),
          href: '/swap',
          showOnMenu: true,
        },
        {
          label: t('Liquidity'),
          href: '/liquidity',
          showOnMenu: true,
        },
        // {
        //   label: t('Bridge'),
        //   href: 'https://bridge.alltra.global/',
        //   supportChainIds: POOL_SUPPORTED_CHAINS,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Stake'),
        //   href: 'https://stake.alltra.global/',
        //   supportChainIds: POOL_SUPPORTED_CHAINS,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Deploy'),
        //   href: 'https://token.alltra.global/',
        //   supportChainIds: POOL_SUPPORTED_CHAINS,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    {
      label: t('Earn'),
      href: '/farms',
      icon: EarnIcon,
      fillIcon: EarnFillIcon,
      image: '/images/decorations/pe2.png',
      supportChainIds: SUPPORT_FARMS,
      items: [
        {
          label: t('Farms'),
          href: '/farms',
          supportChainIds: SUPPORT_FARMS,
          showOnMenu: true,
        },
        {
          label: t('Pools'),
          href: '/pools',
          supportChainIds: POOL_SUPPORTED_CHAINS,
          showOnMenu: true,
        },
        // {
        //   label: t('Bridge'),
        //   href: 'https://bridge.alltra.global/',
        //   supportChainIds: POOL_SUPPORTED_CHAINS,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Stake'),
        //   href: 'https://stake.alltra.global/',
        //   supportChainIds: POOL_SUPPORTED_CHAINS,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
        // {
        //   label: t('Deploy'),
        //   href: 'https://token.alltra.global/',
        //   supportChainIds: POOL_SUPPORTED_CHAINS,
        //   type: DropdownMenuItemType.EXTERNAL_LINK,
        // },
      ].map((item) => addMenuItemSupported(item, chainId)),
    },
    // {
    //   label: t('Bridge'),
    //   href: 'https://bridge.alltra.global/',
    //   icon: BridgeIcon,
    //   fillIcon: TrophyFillIcon,
    //   image: '/images/decorations/pe2.png',
    //   type: DropdownMenuItemType.EXTERNAL_LINK,
    //   // showOnMobile: false
    //   showItemsOnMobile: false,
    // },
    {
      label: t('NFT'),
      href: `${nftsBaseUrl}`,
      icon: NftIcon,
      fillIcon: NftFillIcon,
      supportChainIds: SUPPORT_NFT,
      image: '/images/decorations/nft.png',
      items: [
        {
          label: t('Overview'),
          href: `${nftsBaseUrl}`,
          showOnMenu: true,
        },
        {
          label: t('Collections'),
          href: `${nftsBaseUrl}/collections`,
          showOnMenu: true,
        },
        {
          label: t('Activity'),
          href: `${nftsBaseUrl}/activity`,
          showOnMenu: true,
        },
      ],
    },
    {
      label: t('IFO'),
      href: '/ifo',
      supportChainIds: SUPPORT_IFO,
      showItemsOnMobile: false,
    },
    {
      label: t('Analytics'),
      href: '/info/v3',
      icon: MoreIcon,
      fillIcon: MoreIcon,
      // showOnMobile: false
      showItemsOnMobile: false,
    },
  ].map((item) => addMenuItemSupported(item, chainId))

export default config

// const config: (
//   t: ContextApi['t'],
//   isDark: boolean,
//   languageCode?: string,
//   chainId?: number,
// ) => ConfigMenuItemsType[] = (t, isDark, languageCode, chainId) =>
//   [
//     {
//       label: t('Trade'),
//       icon: SwapIcon,
//       fillIcon: SwapFillIcon,
//       href: '/swap',
//       showItemsOnMobile: false,
//       items: [
//         {
//           label: t('Swap'),
//           href: '/swap',
//         },
//         {
//           label: t('Liquidity'),
//           href: '/liquidity',
//         },
//         {
//           label: t('Perpetual'),
//           href: getPerpetualUrl({
//             chainId,
//             languageCode,
//             isDark,
//           }),
//           confirmModalId: 'usCitizenConfirmModal',
//           type: DropdownMenuItemType.EXTERNAL_LINK,
//         },
//         {
//           label: t('Bridge'),
//           href: 'https://bridge.pancakeswap.finance/',
//           type: DropdownMenuItemType.EXTERNAL_LINK,
//         },
//         {
//           label: `${t('Limit')} (V2)`,
//           href: '/limit-orders',
//           supportChainIds: SUPPORT_ONLY_BSC,
//           image: '/images/decorations/3d-coin.png',
//         },
//         {
//           label: t('Buy Crypto'),
//           LabelIcon: NewIconButton,
//           href: '/buy-crypto',
//           status: { text: t('New'), color: 'success' },
//         },
//       ].map((item) => addMenuItemSupported(item, chainId)),
//     },
//     {
//       label: t('Earn'),
//       href: '/farms',
//       icon: EarnIcon,
//       fillIcon: EarnFillIcon,
//       image: '/images/decorations/pe2.png',
//       supportChainIds: SUPPORT_FARMS,
//       items: [
//         {
//           label: t('Farms'),
//           href: '/farms',
//           supportChainIds: SUPPORT_FARMS,
//         },
//         {
//           label: t('Pools'),
//           href: '/pools',
//           supportChainIds: POOL_SUPPORTED_CHAINS,
//         },
//         {
//           label: t('Liquid Staking'),
//           href: '/liquid-staking',
//           supportChainIds: SUPPORTED_LIQUDITY_CHAIN_IDS,
//         },
//       ].map((item) => addMenuItemSupported(item, chainId)),
//     },
//     {
//       label: t('Win'),
//       href: '/prediction',
//       icon: TrophyIcon,
//       fillIcon: TrophyFillIcon,
//       supportChainIds: SUPPORT_ONLY_BSC,
//       items: [
//         {
//           label: t('Trading Reward'),
//           href: '/trading-reward',
//           hideSubNav: true,
//         },
//         {
//           label: t('Trading Competition'),
//           href: '/competition',
//           image: '/images/decorations/tc.png',
//           hideSubNav: true,
//         },
//         {
//           label: t('Prediction (BETA)'),
//           href: '/prediction',
//           image: '/images/decorations/prediction.png',
//         },
//         {
//           label: t('Lottery'),
//           href: '/lottery',
//           image: '/images/decorations/lottery.png',
//         },
//         {
//           label: t('Pottery (BETA)'),
//           href: '/pottery',
//           image: '/images/decorations/lottery.png',
//         },
//       ],
//     },
//     {
//       label: t('NFT'),
//       href: `${nftsBaseUrl}`,
//       icon: NftIcon,
//       fillIcon: NftFillIcon,
//       supportChainIds: SUPPORT_ONLY_ALL,
//       image: '/images/decorations/nft.png',
//       items: [
//         {
//           label: t('Overview'),
//           href: `${nftsBaseUrl}`,
//         },
//         {
//           label: t('Collections'),
//           href: `${nftsBaseUrl}/collections`,
//         },
//         {
//           label: t('Activity'),
//           href: `${nftsBaseUrl}/activity`,
//         },
//       ],
//     },
//     {
//       label: t('Game'),
//       icon: PancakeProtectorIcon,
//       supportChainIds: SUPPORT_ONLY_BSC,
//       hideSubNav: true,
//       items: [
//         {
//           label: t('Pancake Protectors'),
//           href: 'https://protectors.pancakeswap.finance',
//           type: DropdownMenuItemType.EXTERNAL_LINK,
//           status: { text: t('New'), color: 'success' },
//         },
//       ],
//     },
//     {
//       label: '',
//       href: '/info',
//       icon: MoreIcon,
//       hideSubNav: true,
//       items: [
//         {
//           label: t('Info'),
//           href: '/info/v3',
//         },
//         {
//           label: t('IFO'),
//           href: '/ifo',
//           supportChainIds: SUPPORT_ONLY_BSC,
//           image: '/images/ifos/ifo-bunny.png',
//         },
//         {
//           label: t('Affiliate Program'),
//           href: '/affiliates-program',
//         },
//         {
//           label: t('Voting'),
//           href: '/voting',
//           supportChainIds: SUPPORT_ONLY_BSC,
//           image: '/images/voting/voting-bunny.png',
//         },
//         {
//           type: DropdownMenuItemType.DIVIDER,
//         },
//         {
//           label: t('Leaderboard'),
//           href: '/teams',
//           supportChainIds: SUPPORT_ONLY_BSC,
//           image: '/images/decorations/leaderboard.png',
//         },
//         {
//           type: DropdownMenuItemType.DIVIDER,
//         },
//         {
//           label: t('Blog'),
//           href: 'https://blog.pancakeswap.finance',
//           type: DropdownMenuItemType.EXTERNAL_LINK,
//         },
//         {
//           label: t('Docs'),
//           href: 'https://docs.pancakeswap.finance',
//           type: DropdownMenuItemType.EXTERNAL_LINK,
//         },
//       ].map((item) => addMenuItemSupported(item, chainId)),
//     },
//   ].map((item) => addMenuItemSupported(item, chainId))
