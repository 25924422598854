import { ChainId } from '@pancakeswap/sdk'
import { Address } from 'viem'

import { SupportedChainId } from './supportedChains'

export type ContractAddresses<T extends ChainId = SupportedChainId> = {
  [chainId in T]: Address
}

export const ICAKE = {
  [ChainId.BSC]: '0x27A6ed9dD4d12a9eAee4eD0E41D84dcCe4B7178D',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x5D344AF105958c34fAF15bF28d3088c6e7b66967',
  [ChainId.ALL]: '0xc675a516eea0605F66b11B8a72Cd2561f645C950',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_VAULT = {
  [ChainId.BSC]: '0x4Fd06A69624FC5C4B077B8890DeBC9cf0d91F1A7',
  [ChainId.BSC_TESTNET]: '0x1088Fb24053F03802F673b84d16AE1A7023E400b',
  [ChainId.ETHEREUM]: '0x88300E1490585208Aa5238deDa1BF9cF7244Bb22',
  [ChainId.ALL]: '0x27A6ed9dD4d12a9eAee4eD0E41D84dcCe4B7178D',
} as const satisfies ContractAddresses<SupportedChainId>

export const CAKE_FLEXIBLE_SIDE_VAULT = {
  [ChainId.BSC]: '0x6f876A7ce2e78A7D3030c04DfD3E84B06Ab7d006',
  [ChainId.BSC_TESTNET]: '0x',
  [ChainId.ETHEREUM]: '0x0b11e66406237143Cb08B0310d4D900d953b0Db7',
  [ChainId.ALL]: '0xCfA94C3dbC63BE5863eCe2BAc614e7b4EE1AC41a',
} as const satisfies ContractAddresses<SupportedChainId>
