import { Language } from "../LangSelector/types";
import { FooterLinkType } from "./types";
import { TwitterIcon, TelegramIcon, RedditIcon, InstagramIcon, GithubIcon, DiscordIcon, YoutubeIcon, MediumIcon, GlobeIcon, FacebookIcon } from "../Svg";

export const footerLinks: FooterLinkType[] = [
  {
    label: "Social Media",
    items: [
      {
        label: "Gchain Twitter",
        href: "https://twitter.com/ALLChain",
      },
      {
        label: "Hyper-Dex Exchange Twitter",
        href: "https://twitter.com/BlueLotusALL",
      },
      {
        label: "ALL View app Twitter",
        href: "https://twitter.com/GvaApp",
      },
      {
        label: "ALL Network Discord",
        href: "https://discord.gg/XjHDMXQTjT",
      },
      {
        label: "Blue Hosting Twitter",
        href: "https://twitter.com/BlueHostingGsys",
      },
      {
        label: "ALL Network Telegram",
        href: "https://web.telegram.org/a/#-1619157049",
      },
    ],
  },
  {
    label: "Help",
    items: [
      {
        label: "Customer",
        href: "Support https://docs.pancakeswap.finance/contact-us/customer-support",
      },
      {
        label: "Troubleshooting",
        href: "https://docs.pancakeswap.finance/help/troubleshooting",
      },
      {
        label: "Guides",
        href: "https://docs.pancakeswap.finance/get-started",
      },
    ],
  },
  {
    label: "Developers",
    items: [
      {
        label: "Github",
        href: "https://github.com/pancakeswap",
      },
      {
        label: "Documentation",
        href: "https://docs.pancakeswap.finance",
      },
      {
        label: "Bug Bounty",
        href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
      },
      {
        label: "Audits",
        href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited",
      },
      {
        label: "Careers",
        href: "https://docs.pancakeswap.finance/hiring/become-a-chef",
      },
    ],
  },
];
// export const footerLinks: FooterLinkType[] = [
//   {
//     label: "About",
//     items: [
//       {
//         label: "Contact",
//         href: "https://docs.pancakeswap.finance/contact-us",
//       },
//       {
//         label: "Blog",
//         href: "https://blog.pancakeswap.finance/",
//       },
//       {
//         label: "Community",
//         href: "https://docs.pancakeswap.finance/contact-us/telegram",
//       },
//       {
//         label: "HYDX",
//         href: "https://docs.pancakeswap.finance/tokenomics/cake",
//       },
//       {
//         label: "—",
//       },
//       {
//         label: "Online Store",
//         href: "https://pancakeswap.creator-spring.com/",
//         isHighlighted: true,
//       },
//     ],
//   },
//   {
//     label: "Help",
//     items: [
//       {
//         label: "Customer",
//         href: "Support https://docs.pancakeswap.finance/contact-us/customer-support",
//       },
//       {
//         label: "Troubleshooting",
//         href: "https://docs.pancakeswap.finance/help/troubleshooting",
//       },
//       {
//         label: "Guides",
//         href: "https://docs.pancakeswap.finance/get-started",
//       },
//     ],
//   },
//   {
//     label: "Developers",
//     items: [
//       {
//         label: "Github",
//         href: "https://github.com/pancakeswap",
//       },
//       {
//         label: "Documentation",
//         href: "https://docs.pancakeswap.finance",
//       },
//       {
//         label: "Bug Bounty",
//         href: "https://app.gitbook.com/@pancakeswap-1/s/pancakeswap/code/bug-bounty",
//       },
//       {
//         label: "Audits",
//         href: "https://docs.pancakeswap.finance/help/faq#is-pancakeswap-safe-has-pancakeswap-been-audited",
//       },
//       {
//         label: "Careers",
//         href: "https://docs.pancakeswap.finance/hiring/become-a-chef",
//       },
//     ],
//   },
// ];

export const socials = [
  {
    label: "Website",
    icon: GlobeIcon,
    href: "https://alltra.global/",
    items: null,
  },
  {
    label: "X",
    icon: TwitterIcon,
    href: "https://twitter.com/BarterHyper",
    items: null,
  },
  {
    label: "Facebook",
    icon: FacebookIcon,
    href: "https://www.facebook.com/ozz.metals",
    items: null,
  },
  // {
  //   label: "Telegram",
  //   icon: TelegramIcon,
  //   href: "",
  //   items: null,
  // },
  // {
  //   label: "Discord",
  //   icon: DiscordIcon,
  //   href: "#",
  //   items: null,
  // },
  // {
  //   label: "Github",
  //   icon: GithubIcon,
  //   href: "#",
  //   items: null,
  // },
];

export const langs: Language[] = [...Array(20)].map((_, i) => ({
  code: `en${i}`,
  language: `English${i}`,
  locale: `Locale${i}`,
}));
