import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | Hyper-Dex Exchange',
  defaultTitle: 'Hyper-Dex Exchange',
  description:
    'Cheaper and faster than Uniswap? Discover Hyper-Dex Exchange, the leading DEX on ALL Smart Chain (ALL) with the best farms in DeFi and a lottery for HYDX.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@Hyper-Dex Exchange',
    site: '@Hyper-Dex Exchange',
  },
  openGraph: {
    title: 'Hyper-Dex Exchange - A next evolution DeFi exchange on ALL Smart Chain (ALL)',
    description:
      'Hyper-Dex Exchange part of ALLTRA Network Ecosystem, earn HYDX through farming, staking or lottery,  swap your tokens with security and confidence.',
    // images: [{ url: process.env.NEXT_PUBLIC_URL + '/images/hero.png?v=1.0' }],
  },
}
