import { allTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { CAKE_ALL_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: allTokens.hydx,
    token1: allTokens.wall,
    lpAddress: '0xFE83977154cc6fB3B7239f94248452A96C75643a',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 2,
    token0: allTokens.wall,
    token1: allTokens.usdc,
    lpAddress: '0xDb628cF5F9B00Cec1E1B927BB5361E770E8F2DC2',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 3,
    token0: allTokens.wall,
    token1: allTokens.usdt,
    lpAddress: '0x2266F3B2bF3Cd3689d1FE1d33933E7D47686D5f3',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 4,
    token0: allTokens.hydx,
    token1: allTokens.usdt,
    lpAddress: '0xf0257afe250e0411901BEF35fdd0D2F073563E25',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 5,
    token0: allTokens.wall,
    token1: allTokens.auda,
    lpAddress: '0xB601Ac2327f1075C113C0A8602B933bA897815b4',
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'HYDX',
    lpAddress: '0x0d9793861AEB9244AD1B34375a83A6730F6AdD38',
    token: allTokens.syrup,
    quoteToken: allTokens.wall,
  },
  {
    pid: 4,
    v1pid: 4,
    lpSymbol: 'HYDX-ALL LP',
    lpAddress: CAKE_ALL_LP_MAINNET,
    token: allTokens.hydx,
    quoteToken: allTokens.wall,
    boosted: false,
  },
  {
    pid: 5,
    v1pid: 5,
    lpSymbol: 'ALL-USDC LP',
    lpAddress: '0xb0201c92126C13f5979b3E2c3b7A97a3BD1CEC50',
    token: allTokens.wall,
    quoteToken: allTokens.usdc,
    boosted: false,
  },
  {
    pid: 6,
    v1pid: 6,
    lpSymbol: 'ALL-USDT LP',
    lpAddress: '0xae9B16C50E5e11c0c7388e8289459f1Af1ae47a8',
    token: allTokens.wall,
    quoteToken: allTokens.usdt,
    boosted: false,
  },
  {
    pid: 7,
    v1pid: 7,
    lpSymbol: 'HYDX-USDT LP',
    lpAddress: '0xC33F12b74D62205AF13b4908e4b8d2e684dA89a3',
    token: allTokens.hydx,
    quoteToken: allTokens.usdt,
    boosted: false,
  },
  {
    pid: 8,
    v1pid: 8,
    lpSymbol: 'ALL-AUDA LP',
    lpAddress: '0x3D8B7683c4f4Dffa0657B19baf894d05Eb2B8348',
    token: allTokens.wall,
    quoteToken: allTokens.auda,
    boosted: false,
  },
  //    * V3 by order of release (some may be out of PID order due to multiplier boost)
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export const farmsFinished = [
  // '0x96165F5e73ED28Fe12d1560913aBC97708bE4Ad7',
  // '0x755ad9e117866D083828DfBd4D8F70EaAa3fE8BF',
  // '0x36477c434a1d721E7c9E3f87f9752885737f897c',
  // '0x2683FC29350d95E9efBD1727CE12463aF16CD267',
  // '0x67a7CDba1388578420b652FfB7512AA00b66467d',
  // '0xc976d6827d759F67901eC980dA8a39D07B4C6b06',
  // '0xf7B8746A711feD66bF0c3Fe1a92432099103A5C0',
  // '0x0F65c020CA545982c94cBa756f60FF49DC3D4C71',
  // '0xC8f9eb61C47Fddc7bB0B8f13A5b181D134674Ac5',
  // '0x8Dc17E1D340C676a932D3a2503360aaD1Db5658c',
  // '0xbee38441B2f55434Feaee16502258e3Aa7647293',
  // '0x5fEC9F7399507f7b2eb49c42C77Fa46ea63e0599',
  // '0x33aD4DFf9f4370ccf52C419D9C2BF7Bb2aebD8c5',
]

export default farms
