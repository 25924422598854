import { bscTokens } from '@pancakeswap/tokens'
import { FeeAmount, Pool } from '@pancakeswap/v3-sdk'
import { getAddress } from 'viem'
import { CAKE_BNB_LP_MAINNET } from './common'
import { defineFarmV3Configs } from '../src/defineFarmV3Configs'
import { SerializedFarmConfig } from '..'

export const farmsV3 = defineFarmV3Configs([
  {
    pid: 1,
    token0: bscTokens.hydx,
    token1: bscTokens.wbnb,
    lpAddress: '0x852a128696682Ef812D56b86b1F172546CD50722',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 2,
    token0: bscTokens.usdc,
    token1: bscTokens.wbnb,
    lpAddress: '0x5ec453BC75cB299DDEABB588CD3974Bd95a6F6dA',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 3,
    token0: bscTokens.usdt,
    token1: bscTokens.wbnb,
    lpAddress: '0x420AC491a0965e8036A49FA9Ff99999D68645e55',
    feeAmount: FeeAmount.LOW,
  },
  {
    pid: 4,
    token0: bscTokens.usdt,
    token1: bscTokens.hydx,
    lpAddress: '0x3f8a457b4F8da3551E111545E334487339D8A645',
    feeAmount: FeeAmount.LOW,
  },
])

const farms: SerializedFarmConfig[] = [
  /**
   * These 3 farms (PID 0, 2, 3) should always be at the top of the file.
   */
  {
    pid: 0,
    v1pid: 0,
    lpSymbol: 'HYDX',
    lpAddress: '0x5C999E15B71de2bb8e651f0f999FB0Bc321a0DFe',
    token: bscTokens.syrup,
    quoteToken: bscTokens.wbnb,
  },
  {
    pid: 3,
    v1pid: 3,
    lpSymbol: 'HYDX-BNB LP',
    lpAddress: '0xa9C6Ba127F004acFa0EaC84049be22Aa61B9A3bB',
    token: bscTokens.wbnb,
    quoteToken: bscTokens.hydx,
    boosted: false,
  },
  {
    pid: 4,
    v1pid: 4,
    lpSymbol: 'BNB-USDC LP',
    lpAddress: '0x3BA1CcAcB64469028ef749064B324b4653c3Fb4D',
    token: bscTokens.wbnb,
    quoteToken: bscTokens.usdc,
    boosted: false,
  },
  {
    pid: 5,
    v1pid: 5,
    lpSymbol: 'BNB-USDT LP',
    lpAddress: '0x712714ff6262aB252b54DF68047541946f592D2C',
    token: bscTokens.wbnb,
    quoteToken: bscTokens.usdt,
    boosted: false,
  },
  {
    pid: 6,
    v1pid: 6,
    lpSymbol: 'HYDX-USDT LP',
    lpAddress: '0xb77A67F20aFd68Ba95869E9A9C34Fc1dEA837AAF',
    token: bscTokens.hydx,
    quoteToken: bscTokens.usdt,
    boosted: false,
  },
].map((p) => ({
  ...p,
  token: p.token.serialize,
  quoteToken: p.quoteToken.serialize,
  lpAddress: getAddress(p.lpAddress),
}))

export default farms
