import useSWRImmutable from 'swr/immutable'

export const useCakePrice = () => {
  return useSWRImmutable(
    ['cake-usd-price'],
    async () => {
      const hydx = await (await fetch('https://alltrasonicswap.trade/api/farms/price/cake')).json()
      return hydx.price as string
    },
    {
      refreshInterval: 1_000 * 10,
    },
  )
}
