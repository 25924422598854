import { ChainId } from '@pancakeswap/sdk'

export const SUPPORT_ONLY_BSC = [ChainId.BSC]
export const SUPPORT_ONLY_ALL = [ChainId.ALL]
export const SUPPORT_FARMS = [
  ChainId.BSC,
  // ChainId.BSC_TESTNET,
  ChainId.ETHEREUM,
  // ChainId.GOERLI,
  // ChainId.ZKSYNC_TESTNET,
  // ChainId.POLYGON_ZKEVM_TESTNET,
  // ChainId.POLYGON_ZKEVM,
  // ChainId.ZKSYNC,
  ChainId.ALL,
]
export const SUPPORT_NFT = [ChainId.ETHEREUM, ChainId.ALL, ChainId.BSC]
export const SUPPORT_IFO = [ChainId.ETHEREUM, ChainId.ALL, ChainId.BSC]
