import { ChainId } from '@pancakeswap/sdk'
import { getFullDecimalMultiplier } from '@pancakeswap/utils/getFullDecimalMultiplier'

export const BSC_BLOCK_TIME = 3
export const BLOCKS_TIME = {
  [ChainId.BSC]: 3,
  [ChainId.ETHEREUM]: 12,
  [ChainId.ALL]: 3
}

// CAKE_PER_BLOCK details
// 40 HYDX is minted per block
// 20 HYDX per block is sent to Burn pool (A farm just for burning hydx)
// 10 HYDX per block goes to HYDX syrup pool
// 9 HYDX per block goes to Yield farms and lottery
// CAKE_PER_BLOCK in config/index.ts = 40 as we only change the amount sent to the burn pool which is effectively a farm.
// HYDX/Block in src/views/Home/components/CakeDataRow.tsx = 15 (40 - Amount sent to burn pool)
export const CAKE_PER_BLOCK = 40
export const BLOCKS_PER_DAY = {
  [ChainId.BSC]: (60 / BLOCKS_TIME[ChainId.BSC]) * 60 * 24,
  [ChainId.ETHEREUM]: (60 / BLOCKS_TIME[ChainId.ETHEREUM]) * 60 * 24,
  [ChainId.ALL]: (60 / BLOCKS_TIME[ChainId.ALL]) * 60 * 24,
}
export const BLOCKS_PER_YEAR = {
  [ChainId.BSC]: BLOCKS_PER_DAY[ChainId.BSC] * 365,
  [ChainId.ETHEREUM]: BLOCKS_PER_DAY[ChainId.ETHEREUM] * 365,
  [ChainId.ALL]: BLOCKS_PER_DAY[ChainId.ALL] * 365,
}
export const CAKE_PER_YEAR = {
  [ChainId.BSC]: CAKE_PER_BLOCK * BLOCKS_PER_YEAR[ChainId.BSC],
  [ChainId.ETHEREUM]: CAKE_PER_BLOCK * BLOCKS_PER_YEAR[ChainId.ETHEREUM],
  [ChainId.ALL]: CAKE_PER_BLOCK * BLOCKS_PER_YEAR[ChainId.ALL],
}
export const BASE_URL = process.env.NEXT_PUBLIC_URL
export const BASE_ADD_LIQUIDITY_URL = `${BASE_URL}/add`
export const DEFAULT_TOKEN_DECIMAL = getFullDecimalMultiplier(18)
export const DEFAULT_GAS_LIMIT = 250000n
export const BOOSTED_FARM_GAS_LIMIT = 500000n
export const BOOSTED_FARM_V3_GAS_LIMIT = 1000000n
export const AUCTION_BIDDERS_TO_FETCH = 500
export const RECLAIM_AUCTIONS_TO_FETCH = 500
export const AUCTION_WHITELISTED_BIDDERS_TO_FETCH = 500
export const IPFS_GATEWAY = 'https://ipfs.io/ipfs'
