import { ChainId } from '@pancakeswap/sdk'

export * from './pools'
export * from './contracts'
export * from './supportedChains'

export const BSC_BLOCK_TIME = 3
export const BLOCKS_TIME = {
  [ChainId.BSC]: 3,
  [ChainId.ETHEREUM]: 12,
  [ChainId.ALL]: 3,
}

export const BLOCKS_PER_DAY = {
  [ChainId.BSC]: (60 / BLOCKS_TIME[ChainId.BSC]) * 60 * 24,
  [ChainId.ETHEREUM]: (60 / BLOCKS_TIME[ChainId.ETHEREUM]) * 60 * 24,
  [ChainId.ALL]: (60 / BLOCKS_TIME[ChainId.ALL]) * 60 * 24,
}
export const BLOCKS_PER_YEAR = {
  [ChainId.BSC]: BLOCKS_PER_DAY[ChainId.BSC] * 365,
  [ChainId.ETHEREUM]: BLOCKS_PER_DAY[ChainId.ETHEREUM] * 365,
  [ChainId.ALL]: BLOCKS_PER_DAY[ChainId.ALL] * 365,
}

export const SECONDS_IN_YEAR = 31536000 // 365 * 24 * 60 * 60
