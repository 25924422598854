import { IPFS_GATEWAY } from '../index'
import { Team } from './types'

const teams: Team[] = [
  {
    id: 1,
    name: 'Alltrasonic Galaxy Builders',
    description: "The storm's a-comin! Watch out! These bulls are stampeding in a syrupy surge!",
    images: {
      lg: 'Alltrasonic-Galaxy-Builders-lg.png',
      md: 'Alltrasonic-Galaxy-Builders-md.png',
      sm: 'Alltrasonic-Galaxy-Builders-sm.png',
      alt: 'Alltrasonic-Galaxy-Builders-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmPWvhjQSUt3G3akD1ToMsXzNwUkp7xRSBBUinLkvKRN4n/Alltrasonic Galaxy Builders.png`,
    },
    background: 'Alltrasonic-Galaxy-Builders-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
  {
    id: 2,
    name: 'ALLTRASONIC SWAP Commanders',
    description: "The flippening is coming. Don't get in these bunnies' way, or you'll get flipped, too!",
    images: {
      lg: 'ALLTRASONIC-SWAP-Commanders-lg.png',
      md: 'ALLTRASONIC-SWAP-Commanders-md.png',
      sm: 'ALLTRASONIC-SWAP-Commanders-sm.png',
      alt: 'ALLTRASONIC-SWAP-Commanders-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmPWvhjQSUt3G3akD1ToMsXzNwUkp7xRSBBUinLkvKRN4n/ALLTRASONIC SWAP Commanders.png`,
    },
    background: 'ALLTRASONIC-SWAP-Commanders-bg.svg',
    textColor: '#FFFFFF',
    users: 0,
    points: 0,
  },
  {
    id: 3,
    name: 'ALLTRAVARERSAL Traders',
    description: 'Can you stand the heat? Stay out of the kitchen or you might get burned to a crisp!',
    images: {
      lg: 'ALLTRAVARERSAL-Traders-lg.png',
      md: 'ALLTRAVARERSAL-Traders-md.png',
      sm: 'ALLTRAVARERSAL-Traders-sm.png',
      alt: 'ALLTRAVARERSAL-Traders-alt.png',
      ipfs: `${IPFS_GATEWAY}/QmPWvhjQSUt3G3akD1ToMsXzNwUkp7xRSBBUinLkvKRN4n/ALLTRAVARERSAL Traders.png`,
    },
    background: 'ALLTRAVARERSAL-Traders-bg.svg',
    textColor: '#191326',
    users: 0,
    points: 0,
  },
]

export default teams
